import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Translation, TranslocoLoader } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root'
})
export class TranslocoHttpLoader implements TranslocoLoader
{
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get translation
     *
     * @param lang
     */
    getTranslation(lang: string): Observable<Translation>
    {
        // Get current date and set time to 00:00:00
        // Me aseguro de que el archivo de traducción se vuelva a cargar cada día
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        // Convert to Unix timestamp (milliseconds)
        const timestamp = today.getTime();
        
        return this._httpClient.get<Translation>(`./assets/i18n/${lang}.json?v=${timestamp}`);
    }
}
